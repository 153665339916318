import { useMutation } from '@apollo/client';
import { Switch } from '@mui/material';
import { UPDATE_USER_FLAG } from 'queries/admin';
import { useState } from 'react';

export default function FeatureFlagListItem({ flag, user }) {
  const [enabled, setEnabled] = useState(user.flags.includes(flag));
  const [updateUserFlag, { loading }] = useMutation(UPDATE_USER_FLAG, {
    variables: { userId: user.id },
  });

  return (
    <li key={flag}>
      <Switch
        disabled={loading}
        checked={enabled}
        onChange={() =>
          updateUserFlag({ variables: { flag, enabled: !enabled } }).then(() =>
            setEnabled(!enabled)
          )
        }
      />
      <code>{flag}</code>
    </li>
  );
}
