import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useMutation, useQuery } from '@apollo/client';
import { CHANGE_USER_EMAIL, GET_FEATURE_FLAGS } from 'queries/admin';
import FeatureFlagListItem from './FeatureFlagListItem';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'fontFamily': "'Roboto', 'Helvetica', 'Arial', sans-serif",
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

export const UserOptions = ({ user, userId, refetch }) => {
  const classes = useStyles();
  const [newEmail, setNewEmail] = useState('');
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [changeUserEmail] = useMutation(CHANGE_USER_EMAIL, {
    variables: {
      userId,
      newEmail,
    },
    onCompleted: (data) => {
      if (data?._changeUserEmail) {
        refetch();
      }
    },
  });

  const featureFlags = useQuery(GET_FEATURE_FLAGS).data?._getFeatureFlags;

  const emailIsValid = (email) => {
    // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
    return /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      email
    );
  };

  const handleChange = (event) => {
    event.persist();
    setNewEmail(event.target.value);
    setButtonEnabled(emailIsValid(event.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await changeUserEmail();
  };

  return (
    <div className={classes.root}>
      <div>
        <p>Feature flags:</p>
        <ul>
          {featureFlags?.map((flag) => (
            <FeatureFlagListItem flag={flag} user={user} key={flag} />
          ))}
        </ul>
      </div>

      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="newEmail"
          label="New email"
          name="newEmail"
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!buttonEnabled || !user}
          className={classes.submit}
          value="test"
        >
          Change User Email
        </Button>
      </form>
    </div>
  );
};
