import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  mutation login($input: LoginCredentials) {
    login(input: $input) {
      user {
        id
        email
        firstName
        lastName
        roles
      }
      token
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($userId: ID!) {
    _getUserById(userId: $userId) {
      id
      firstName
      lastName
      createdAt
      updatedAt
      email
      publishers {
        id
        name
      }
      organization {
        id
        name
        createdAt
        members {
          users {
            id
            _latestSessionCreatedAt
          }
          totalUsers
        }
        sessionSetups {
          totalSessionSetups
        }
        sessions {
          totalSessions
        }
      }
      sessionSetups {
        sessionSetups {
          id
          title
          sessions {
            totalSessions
          }
          creator {
            id
            firstName
            lastName
          }
          createdAt
        }
        totalSessionSetups
      }
      sessions {
        sessions {
          id
          pin
          title
          participantCount
          creator {
            id
            firstName
            lastName
          }
          createdAt
        }
        totalSessions
      }
      deactivated
      flags
      _latestSessionCreatedAt
      _sessionsCreatedEachDay
      _sessionSetupsCreatedEachDay
    }
  }
`;

export const GET_ALL_USERS = gql`
  query getAllUsers($page: Int!, $search: String!) {
    _getAllUsers(page: $page, search: $search) {
      totalUsers
      users {
        id
        firstName
        lastName
        email
        createdAt
        publishers {
          id
        }
        organization {
          id
          name
        }
        sessions {
          totalSessions
        }
        sessionSetups {
          totalSessionSetups
        }
        _latestSessionCreatedAt
      }
    }
  }
`;

export const ADD_USER_TO_ORGANIZATION = gql`
  mutation addUserToOrganization($email: String!, $orgId: ID!) {
    _addUserToOrganization(email: $email, orgId: $orgId) {
      email
      firstName
      lastName
      organization {
        name
      }
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation deactivateUser($userId: ID!) {
    _deactivateUser(userId: $userId) {
      deactivated
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation activateUser($userId: ID!) {
    _activateUser(userId: $userId) {
      deactivated
    }
  }
`;
