import { gql } from '@apollo/client';

export const GET_DASHBOARD_DATA = gql`
  query getDashboardData {
    _getDashboardData {
      totalUsers
      lastWeekUsers
      totalSessionSetups
      lastWeekSessionSetups
      totalParticipants
      lastWeekParticipants
      totalSessions
      lastWeekSessions
      usersCreatedEachDay
      sessionSetupsCreatedEachDay
      participantsCreatedEachDay
      sessionsCreatedEachDay
    }
  }
`;

export const GET_APPROVED_EMAILS = gql`
  query getApprovedEmails {
    _getApprovedEmails
  }
`;

export const GET_FEATURE_FLAGS = gql`
  query getFeatureFlags {
    _getFeatureFlags
  }
`;

export const CHANGE_USER_EMAIL = gql`
  mutation changeUserEmail($userId: ID!, $newEmail: String!) {
    _changeUserEmail(userId: $userId, newEmail: $newEmail)
  }
`;

export const CREATE_PUBLISHER = gql`
  mutation createPublisher($name: String!) {
    _createPublisher(name: $name)
  }
`;

export const CHANGE_ORGANIZATION_GOOGLE_HOSTED_DOMAIN = gql`
  mutation changeOrganizationGoogleHostedDomain($orgId: ID!, $hd: String!) {
    _changeOrganizationGoogleHostedDomain(orgId: $orgId, hd: $hd) {
      id
    }
  }
`;

export const CHANGE_ORGANIZATION_AZURE_AD_TENANT_ID = gql`
  mutation changeOrganizationAzureADTenantID($orgId: ID!, $tenantid: String!) {
    _changeOrganizationAzureADTenantID(orgId: $orgId, tenantid: $tenantid) {
      id
    }
  }
`;

export const APPROVE_USER_EMAIL = gql`
  mutation approveUserEmail($email: String!) {
    _approveUserEmail(email: $email)
  }
`;

export const UPDATE_USER_FLAG = gql`
  mutation updateUserFlag(
    $userId: ID!
    $flag: FeatureFlag!
    $enabled: Boolean!
  ) {
    _updateUserFlag(userId: $userId, flag: $flag, enabled: $enabled)
  }
`;
