import Table from '../components/Table/Table.js';
import moment from 'moment';

const latestCreatedAt = (dates) =>
  dates.reduce((a, b) =>
    new Date(a).getTime() > new Date(b).getTime() ? a : b
  );

const generateSessionSetups = {
  titles: ['Title', 'Creator', 'Sessions', 'Date'],
  map: (el) => [
    el.id,
    el.title || '<empty string>',
    el.creator ? `${el.creator.firstName} ${el.creator.lastName}` : '---',
    el.sessions.totalSessions,
    moment(el.createdAt).fromNow(),
  ],
};

const generateSessions = {
  titles: ['PIN', 'Title', 'Creator', 'Participants', 'Date'],
  map: (el) => [
    el.id,
    el.pin || '---',
    el.title || '<empty string>',
    el.creator ? `${el.creator.firstName} ${el.creator.lastName}` : '---',
    el.participantCount,
    moment(el.createdAt).fromNow(),
  ],
};

const generateProducts = {
  titles: ['Title', 'Shared', 'Session Setups', 'Purchases', 'Date'],
  map: (el) => [
    el.id,
    el.title || '<empty string>',
    el.shared ? '🟢' : '⭕️',
    el.sessionSetups.length,
    el.purchases.length,
    moment(el.createdAt).fromNow(),
  ],
};

const generateOrganizations = {
  titles: [
    'Name',
    'Members',
    'Session Setups',
    'Sessions',
    'Last Session',
    'Date',
  ],
  map: (el) => {
    const sessionDates = el.members.users.map((x) => x._latestSessionCreatedAt);
    return [
      el.id,
      el.name,
      el.members.totalUsers,
      el.sessionSetups.totalSessionSetups,
      el.sessions.totalSessions,
      sessionDates?.length
        ? moment(latestCreatedAt(sessionDates)).fromNow()
        : '---',
      moment(el.createdAt).fromNow(),
    ];
  },
};

const generatePurchases = {
  titles: [
    'State',
    'Organization',
    'User',
    'Price',
    'Payment Due At',
    'Due Payments',
    'Revoked',
    'Date',
  ],
  map: (el) => {
    const timeSincePurchase = Date.now() - new Date(el.createdAt).getTime();
    const timeUntilNextPayment =
      el.recurringDays * 86400_000 -
      (timeSincePurchase % (el.recurringDays * 86400_000));
    const nextPaymentAt = new Date(Date.now() + timeUntilNextPayment);
    const dueAt = new Date(el.createdAt);
    if (el.recurringDays)
      dueAt.setDate(dueAt.getDate() + el.recurringDays * el.timesPaid);

    return [
      el.id,
      el.revokedAt ? '🔴 Revoked' : el.pending ? '🟡 Pending' : '🟢 Verified',
      el.organization.name,
      `${el.user.firstName} ${el.user.lastName}`,
      el.price ? `${el.price.amount} ${el.price.currency}` : '---',
      el.recurringDays
        ? `${moment(dueAt).fromNow()}` +
          (dueAt.getTime() < Date.now()
            ? ` (next: ${moment(nextPaymentAt).fromNow()})`
            : '')
        : '---',
      el.duePayments ?? '---',
      el.revokedAt ? moment(el.revokedAt).fromNow() : '---',
      moment(el.createdAt).fromNow(),
    ];
  },
};

const generatePublishers = {
  titles: ['State', 'Name', 'Products', 'Users', 'Date'],
  map: (el) => [
    el.id,
    el.deactivated ? '🔴 Deactivated' : '🟢 Active',
    el.name,
    el.products.length,
    el.users.length,
    moment(el.createdAt).fromNow(),
  ],
};

const generateUsers = {
  titles: [
    'Name',
    'Email',
    'Session Setups',
    'Sessions',
    'Last Session',
    'Org',
    'Date',
  ],
  map: (el) => [
    el.id,
    `${el.firstName} ${el.lastName}`,
    el.email,
    el.sessionSetups.totalSessionSetups,
    el.sessions.totalSessions,
    el._latestSessionCreatedAt
      ? moment(el._latestSessionCreatedAt).fromNow()
      : '---',
    el.organization?.name ?? '---',
    moment(el.createdAt).fromNow(),
  ],
};

const tableGenerator =
  (generator) =>
  ({ data, cellClicked, extras }) =>
    (
      <Table
        tableHeaderColor="primary"
        tableHead={[...generator.titles, ...(extras?.titles ?? [])]}
        tableData={data
          ?.filter(Boolean)
          .map((x) => [...generator.map(x), ...(extras?.map?.(x) ?? [])])}
        cellClicked={cellClicked ?? (() => {})}
      />
    );

const tables = {
  sessionSetups: tableGenerator(generateSessionSetups),
  sessions: tableGenerator(generateSessions),
  products: tableGenerator(generateProducts),
  organizations: tableGenerator(generateOrganizations),
  purchases: tableGenerator(generatePurchases),
  publishers: tableGenerator(generatePublishers),
  users: tableGenerator(generateUsers),
};

export default tables;
